import React, { useEffect, useState } from 'react'
import {
  Affix,
  Button,
  Divider,
  Image as AntdImage,
  List,
  Popconfirm,
  Segmented,
  Typography,
  Upload
} from 'antd'
import PropTypes from 'prop-types'
import { ImageAnnotator, useImageAnnotator } from 'react-image-label'
import { useDispatch, useSelector } from 'react-redux'
import { CSS_VARIABLES, DEFAULT_LIMIT, REDUX_STATE } from '@src/constants'
import {
  CheckSquareTwoTone,
  DeleteTwoTone,
  InboxOutlined,
  LeftOutlined,
  SaveOutlined,
  UndoOutlined
} from '@ant-design/icons'
import { fileActions } from '@store/actions/file'
import { FILE_FIELDS } from '@constants/db'
import { useShowMessages } from '@src/utils/hooks'
import { getCSSVariable, isMobile, isMobileDevices } from '@src/utils'
import { HoverCardWrapper } from '@src/styles/wrapper'
import BasicActionButtons from '@components/basic-action-buttons'
import _ from 'lodash'

const CATEGORIES = [
  [
    FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.ENUMS.FOREHEAD,
    FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.ENUMS.TOP_LEFT
  ],
  [
    FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.ENUMS.FOREHEAD,
    FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.ENUMS.TOP_RIGHT
  ],
  [
    FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.ENUMS.FOREHEAD,
    FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.ENUMS.BOTTOM_LEFT
  ],
  [
    FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.ENUMS.FOREHEAD,
    FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.ENUMS.BOTTOM_RIGHT
  ]
]

const FILE_FILTER = {
  NON_LABELED: 'Non labeled',
  LABELED: 'Labeled'
}

/**
 * Popup when right click annotation
 * @param offset
 * @param onClose
 * @param onDelete
 * @param onEdit
 * @returns {Element}
 * @constructor
 */
const Dialog = ({ offset, onEdit, onClose, onDelete }) => {
  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: 'wheat',
        borderRadius: '10px'
      }}
      role='button'
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          left: offset.X,
          top: offset.Y,
          backgroundColor: 'wheat',
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          gap: '15px',
          height: 'fit-content',
          width: 'fit-content',
          maxWidth: '200px',
          borderRadius: '10px'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onEdit} icon={<CheckSquareTwoTone />} />
          <Button onClick={onDelete} icon={<DeleteTwoTone twoToneColor='#eb2f96' />} />
        </div>
      </div>
    </div>
  )
}

const ImageLabelling = ({ toggleDisplay }) => {
  const dispatch = useDispatch()
  const error = useSelector(
    (state) => state?.[REDUX_STATE.FILE.NAME]?.[REDUX_STATE.FILE.FIELDS.ERROR]
  )
  const isGettingFiles = useSelector(
    (state) => state?.[REDUX_STATE.FILE.NAME]?.[REDUX_STATE.FILE.FIELDS.IS_GETTING]
  )
  const isUploadingFiles = useSelector(
    (state) => state?.[REDUX_STATE.FILE.NAME]?.[REDUX_STATE.FILE.FIELDS.IS_UPLOADING]
  )
  const files = useSelector(
    (state) => state?.[REDUX_STATE.FILE.NAME]?.[REDUX_STATE.FILE.FIELDS.FILES]
  )

  const { setHandles, annotator } = useImageAnnotator()
  const [file, setFile] = useState([])
  const [dialog, setDialog] = useState({ show: false, shape: undefined })
  const [uploadMessage, setUploadMessage] = useState({ error: null, success: null })
  const [skip, setSkip] = useState(0)
  const [fileFilter, setFileFilter] = useState(FILE_FILTER.NON_LABELED)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [alreadyDrawnShapes, setAlreadyDrawnShapes] = useState([])
  const [alreadyAddedCategories, setAlreadyAddedCategories] = useState([])

  const isAllPositionAdded = CATEGORIES.every((each) => {
    return alreadyAddedCategories?.includes(each.join('-'))
  })

  /**
   * Get files
   */
  useEffect(() => {
    dispatch(
      fileActions.getFiles({
        [FILE_FIELDS.SHAPES.NAME]: fileFilter === FILE_FILTER.LABELED,
        skip: 0,
        limit: DEFAULT_LIMIT
      })
    )
  }, [dispatch, fileFilter])

  /**
   * Set
   */
  useEffect(() => {
    const notAddedCategories = CATEGORIES?.filter(
      (each) => !alreadyAddedCategories.includes(each.join('-'))
    )

    setSelectedCategory(notAddedCategories?.[0])
  }, [alreadyAddedCategories])

  /**
   * Prevent from adding more dots when all 4 positions added
   */
  useEffect(() => {
    if (isAllPositionAdded) {
      annotator?.stop()
    } else {
      annotator?.drawDot()
    }
  }, [annotator, isAllPositionAdded])

  useShowMessages({ error: error || uploadMessage.error, success: uploadMessage.success })

  /**
   * Handle get file segment change
   * @param {String} value
   */
  const onFilterChange = (value) => {
    setFileFilter(value)
  }

  /**
   * When a bound box draw set default annotation
   */
  const onAnnotationDraw = (shape) => {
    if (!_.isEmpty(selectedCategory)) {
      const id = shape.id
      annotator?.updateCategories(id, selectedCategory)
      setAlreadyDrawnShapes([...alreadyDrawnShapes, shape?.[FILE_FIELDS.SHAPES.FIELDS.TYPE.NAME]])
      setAlreadyAddedCategories([...alreadyAddedCategories, selectedCategory?.join('-')])
      annotator?.stop()
      annotator?.drawDot()
    }
  }

  /**
   * Show edit bound box popup
   * @param shape
   */
  const showCategoriesDialog = (shape) => {
    setDialog({ show: true, shape })
  }

  /**
   * Hide edit bound box popup
   */
  const hideDialog = () => setDialog({ show: false, shape: undefined })

  /**
   * Change categories
   * @param {Array} items
   */
  const selectedCategoriesChanged = (items) => {
    dialog.shape.categories = items
    setDialog({ ...dialog })
  }

  /**
   * Handle confirm categories change
   */
  const hideAndUpdateCategories = () => {
    if (dialog.show) {
      annotator.updateCategories(dialog.shape.id, dialog.shape.categories)
      hideDialog()
      annotator?.stop()
      annotator?.drawDot()
    }
  }

  /**
   * Load more button
   * @type {React.JSX.Element|null}
   */
  const loadMore =
    !isGettingFiles && !isUploadingFiles ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px'
        }}
      >
        <Button
          onClick={() => {
            const newSkip = skip + DEFAULT_LIMIT
            dispatch(
              fileActions.loadMoreFiles({
                [FILE_FIELDS.SHAPES.NAME]: fileFilter === FILE_FILTER.LABELED,
                skip: newSkip,
                limit: DEFAULT_LIMIT
              })
            )
            setSkip(newSkip)
          }}
        >
          loading more
        </Button>
      </div>
    ) : null

  /**
   * Upload file
   * @param onSuccess
   * @param onError
   * @param file
   * @returns {Promise<void>}
   */
  const handleUpload = async ({ onSuccess, onError, file }) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      dispatch(fileActions.upload(formData)).then((res) => onSuccess(res))
    } catch (err) {
      onError({ err })
    }
  }

  /**
   * Handle click to upload file
   * @param {Object} info
   */
  const onChange = (info) => {
    const { status } = info.file

    if (status === 'done') {
      setUploadMessage({
        error: null,
        success: `${info.file.name} file uploaded successfully.`
      })
    } else if (status === 'error') {
      setUploadMessage({
        error: `${info.file.name} file upload failed.`,
        success: null
      })
    }
  }

  /**
   * Delete file
   * @param {String} id
   */
  const deleteImage = (id) => {
    dispatch(fileActions.deleteFile(id))
  }

  /**
   * Update annotation info
   * @param {String} id
   */
  const saveAnnotation = (id) => {
    if (isAllPositionAdded) {
      const img = new Image()
      img.src = file?.url
      let size
      img.onload = () => {
        size = { width: img.naturalWidth, height: img.naturalHeight }
        dispatch(
          fileActions.updateFile({
            id,
            body: {
              [FILE_FIELDS.SHAPES.NAME]: annotator.getShapes(),
              [FILE_FIELDS.EXTRA.NAME]: {
                ...file?.[FILE_FIELDS.EXTRA.NAME],
                [FILE_FIELDS.EXTRA.FIELDS.WIDTH.NAME]: size.width,
                [FILE_FIELDS.EXTRA.FIELDS.HEIGHT.NAME]: size.height
              }
            }
          })
        )
      }
    }
  }

  return (
    <HoverCardWrapper
      style={{
        height:
          !isMobileDevices &&
          `calc(100vh - ${getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT)} - ${getCSSVariable(CSS_VARIABLES.FOOTER_HEIGHT)} - 10px)`
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Affix offsetTop={parseInt(getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT).replace('px', ''))}>
          <div style={{ backgroundColor: 'white', padding: '10px 0' }}>
            <BasicActionButtons
              cancelText={'Back'}
              isShowOk={false}
              cancelIcon={<LeftOutlined />}
              onCancel={toggleDisplay}
            />
            <div
              style={{
                textAlign: 'center',
                fontSize: `${getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_2)}px`,
                fontWeight: 600
              }}
            >
              Image labelling
            </div>
          </div>
        </Affix>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            height: 'calc(100% - 96px)',
            overflowY: 'hidden !important'
          }}
        >
          {/*Left*/}
          <div
            style={{
              flexBasis: isMobile ? '100%' : '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px'
            }}
          >
            <div style={{ height: '200px' }}>
              <Upload.Dragger
                name={'file'}
                multiple={true}
                action={null}
                fileList={files}
                showUploadList={false}
                customRequest={handleUpload}
                onChange={onChange}
                accept='image/*'
              >
                <Typography.Paragraph>
                  <InboxOutlined />
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Click or drag file to this area to upload
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Support for a single or bulk image(s) upload.
                </Typography.Paragraph>
              </Upload.Dragger>
            </div>
            <Segmented
              options={Object.values(FILE_FILTER)}
              value={fileFilter}
              onChange={onFilterChange}
            />
            <List
              loading={isGettingFiles}
              itemLayout='horizontal'
              loadMore={loadMore}
              dataSource={files}
              style={{
                // padding, title, upload area
                height:
                  !isMobileDevices &&
                  'calc(100vh - 200px - 96px - 30px - 96px - 24px - 24px - 100px)',
                overflowY: !isMobileDevices && 'scroll'
              }}
              renderItem={(item) => (
                <List.Item
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setFile(null)
                    setFile(item)
                  }}
                  actions={
                    !isMobileDevices && [
                      <Popconfirm
                        key={'delete-image-btn'}
                        title={'Sure to delete it?'}
                        onConfirm={() => deleteImage(item?.[FILE_FIELDS.ID.NAME])}
                        placement={'right'}
                      >
                        <Button
                          icon={<DeleteTwoTone twoToneColor='#eb2f96' />}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Popconfirm>
                    ]
                  }
                >
                  <AntdImage
                    src={item.url}
                    height={50}
                    width={50}
                    style={{ objectFit: 'cover' }}
                    preview={false}
                  />
                </List.Item>
              )}
            />
          </div>
          {!isMobileDevices && <Divider type={'vertical'} style={{ height: '100%' }} />}
          {/*Right*/}
          {!isMobileDevices && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px', flexBasis: '50%' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center'
                  }}
                >
                  {!selectedCategory && !_.isEmpty(file) && (
                    <Typography.Text type={'success'}>You have drawn all positions</Typography.Text>
                  )}
                  {selectedCategory && !_.isEmpty(file) && (
                    <Typography.Text type={'warning'}>
                      Please draw <strong>{selectedCategory?.join('-')}</strong>
                    </Typography.Text>
                  )}
                  {!_.isEmpty(file) && <Divider type={'vertical'} />}
                  <Button
                    danger={true}
                    icon={<UndoOutlined />}
                    disabled={alreadyDrawnShapes?.length === 0}
                    onClick={() => {
                      const shapes = annotator.getShapes()
                      const lastOne = shapes[shapes.length - 1]
                      setAlreadyDrawnShapes(
                        alreadyDrawnShapes.slice(0, alreadyDrawnShapes?.length - 1)
                      )
                      if (lastOne) {
                        annotator.delete(lastOne?.[FILE_FIELDS.SHAPES.FIELDS.ID.NAME])
                      }
                    }}
                  />
                  <Divider type={'vertical'} />
                  <Button
                    type={'primary'}
                    disabled={_.isEmpty(file) || !isAllPositionAdded}
                    icon={<SaveOutlined />}
                    onClick={() => {
                      saveAnnotation(file?.[FILE_FIELDS.ID.NAME])
                    }}
                  />
                </div>
                <ImageAnnotator
                  setHandles={setHandles}
                  naturalSize={false}
                  width={600}
                  height={600}
                  zoom={1}
                  imageUrl={file?.url}
                  shapes={file?.[FILE_FIELDS.SHAPES.NAME]}
                  onContextMenu={showCategoriesDialog}
                  onAdded={onAnnotationDraw}
                  onReady={(annotator) => {
                    setAlreadyAddedCategories(
                      file?.[FILE_FIELDS.SHAPES.NAME]?.map((each) =>
                        each?.[FILE_FIELDS.SHAPES.FIELDS.CATEGORIES.NAME]?.join('-')
                      )
                    )
                    annotator.drawDot()
                  }}
                />
                {dialog.show && (
                  <Dialog
                    items={dialog.shape.categories}
                    itemsChanged={selectedCategoriesChanged}
                    onEdit={() => {
                      annotator.edit(dialog.shape.id)
                      hideAndUpdateCategories()
                    }}
                    onDelete={() => {
                      annotator.delete(dialog.shape.id)
                      setAlreadyAddedCategories(
                        alreadyAddedCategories?.filter(
                          (each) => each !== dialog.shape.categories?.join('-')
                        )
                      )
                      hideDialog()
                    }}
                    onClose={hideAndUpdateCategories}
                    offset={dialog.shape.getCenterWithOffset()}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </HoverCardWrapper>
  )
}

Dialog.propTypes = {
  offset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

ImageLabelling.propTypes = {
  toggleDisplay: PropTypes.func.isRequired
}

export default ImageLabelling
