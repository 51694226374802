import { BaseApi } from '@src/apis/base'

class AuthApi extends BaseApi {
  /**
   * Login
   * @param {String} email
   * @param {String} password
   * @returns {Promise<*>}
   */
  async login({ email, password }) {
    try {
      const res = await this.client.post(this.endpoints.AUTH.LOGIN, {
        email,
        password
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Logout
   * @param id
   * @return {Promise<*>}
   */
  async logout() {
    try {
      const res = await this.client.post(this.endpoints.AUTH.LOGOUT)
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Register
   * @param {String} email
   * @param {String} password
   * @param {String} firstName
   * @param {String} lastName
   * @param {String} studyId
   * @returns {Promise<*>}
   */
  async register({ email, password, firstName, lastName, studyId }) {
    try {
      const res = await this.client.post(this.endpoints.AUTH.REGISTER, {
        email,
        password,
        firstName,
        lastName,
        studyId
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Get current logged in user
   * @returns {Promise<*>}
   */
  async getCurrent() {
    try {
      const res = await this.client.get(this.endpoints.AUTH.CURRENT)
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Update password
   * @param {String} passwordCurrent
   * @param {String} password
   * @returns {Promise<*>}
   */
  async updatePassword({ passwordCurrent, password }) {
    try {
      const res = await this.client.post(this.endpoints.AUTH.UPDATE_PASSWORD, {
        passwordCurrent,
        password
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Delete session to force logout
   * @param {String} id - session id
   * @returns {Promise<*>}
   */
  async forceLogout(id) {
    try {
      await this.client.delete(this.endpoints.AUTH.FORCE_LOGOUT(id))
      return id
    } catch (e) {
      this.errorHandler(e)
    }
  }
  
  /**
   * Handle reset password request
   * @param email
   * @return {Promise<*>}
   */
  async resetPasswordRequest(email) {
    try {
      const res = await this.client.post(this.endpoints.AUTH.RESET_PASSWORD_REQUEST, {
        email
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
  
  /**
   * Handle reset password
   * @param token
   * @param body
   * @return {Promise<*>}
   */
  async resetPassword({ token, body }) {
    try {
      const res = await this.client.post(this.endpoints.AUTH.RESET_PASSWORD(token), body)
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
}

export { AuthApi }
