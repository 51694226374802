import { REDUX_STATE } from '@src/constants'
import { userActions } from '@store/actions/user'
import { createSlice } from '@reduxjs/toolkit'
import { USER_FIELDS } from '@constants/db'
import _ from 'lodash'

const { FIELDS } = REDUX_STATE.USER

const userSlice = createSlice({
  name: REDUX_STATE.USER.NAME,
  initialState: {
    [FIELDS.USERS]: [],
    [FIELDS.TOTAL]: 0,
    [FIELDS.IS_GETTING_USERS]: false,
    [FIELDS.IS_UPDATING]: false,
    [FIELDS.IS_DELETING]: false,
    [FIELDS.ERROR]: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userActions.getUsers.pending, (state) => {
        state[FIELDS.IS_GETTING_USERS] = true
        state[FIELDS.ERROR] = null
      })
      .addCase(userActions.getUsers.fulfilled, (state, action) => {
        state[FIELDS.IS_GETTING_USERS] = false
        state[FIELDS.USERS] = action.payload.users
        state[FIELDS.TOTAL] = action.payload?.total
      })
      .addCase(userActions.getUsers.rejected, (state) => {
        state[FIELDS.IS_GETTING_USERS] = false
        state[FIELDS.USERS] = null
      })
      .addCase(userActions.updateUser.pending, (state) => {
        state[FIELDS.IS_UPDATING] = true
        state[FIELDS.ERROR] = null
      })
      .addCase(userActions.updateUser.fulfilled, (state, action) => {
        const id = action.payload?.[USER_FIELDS.ID.NAME]
        const index = _.findIndex(state[FIELDS.USERS], { [USER_FIELDS.ID.NAME]: id })
        if (index !== -1) {
          state[FIELDS.USERS] = [
            ...state[FIELDS.USERS].slice(0, index),
            { ...state[FIELDS.USERS]?.[index], ...action.payload },
            ...state[FIELDS.USERS].slice(index + 1)
          ]
        }
        state[FIELDS.IS_UPDATING] = false
      })
      .addCase(userActions.updateUser.rejected, (state, action) => {
        state[FIELDS.IS_UPDATING] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(userActions.deleteUser.pending, (state) => {
        state[FIELDS.IS_DELETING] = true
        state[FIELDS.ERROR] = null
      })
      .addCase(userActions.deleteUser.fulfilled, (state, action) => {
        const id = action.payload
        state[FIELDS.USERS] = state[FIELDS.USERS]?.filter(
          (each) => each[USER_FIELDS.ID.NAME] !== id
        )
        state[FIELDS.IS_DELETING] = false
      })
      .addCase(userActions.deleteUser.rejected, (state, action) => {
        state[FIELDS.IS_DELETING] = false
        state[FIELDS.ERROR] = action.error
      })
  }
})

export default userSlice.reducer
