import React, { useEffect, useState } from 'react'
import SessionBriefCard from '@components/session-page/session-brief-card'
import { Affix, Result, Segmented, Select, Spin, Timeline, Typography } from 'antd'
import _ from 'lodash'
import { getCSSVariable } from '@src/utils'
import {
  CSS_VARIABLES,
  DATE_FORMAT,
  MESSAGES,
  REDUX_STATE,
  SESSION_STATUS_COLORS
} from '@src/constants'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'
import { questionSetActions } from '@store/actions/question-set'
import { recordActions } from '@store/actions/record'
import { QUESTION_SET_FIELDS, RECORD_FIELDS } from '@constants/db'
import FeedbackBriefCard from '@components/session-page/feedback-brief-card'
import { useShowMessages } from '@src/utils/hooks'

const STATUS = {
  OPEN: 'Open',
  FINISHED: 'Finished'
}

/**
 * Filter session based on status
 * @param recordQuestions
 * @param questionSetQuestions
 * @param filter
 * @returns {*[]}
 */
const useFilteredRecord = ({ recordQuestions, questionSetQuestions, filter }) => {
  const [result, setResult] = useState([])

  useEffect(() => {
    const temp = []

    questionSetQuestions?.forEach((item) => {
      const recordForCurrentSession = _.find(recordQuestions, {
        [RECORD_FIELDS.RESPONSES.FIELDS.QUESTION_ID.NAME]:
          item?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.ID.NAME]
      })

      if (filter === STATUS.OPEN) {
        if (
          ![RECORD_FIELDS.RESPONSES.FIELDS.STATUS.ENUMS.COMPLETED].includes(
            recordForCurrentSession?.[RECORD_FIELDS.RESPONSES.FIELDS.STATUS.NAME]
          )
        ) {
          temp.push({
            ...item,
            [RECORD_FIELDS.RESPONSES.FIELDS.CREATED_AT.NAME]:
              recordForCurrentSession?.[RECORD_FIELDS.RESPONSES.FIELDS.CREATED_AT.NAME]
          })
        }
      } else {
        if (
          [RECORD_FIELDS.RESPONSES.FIELDS.STATUS.ENUMS.COMPLETED].includes(
            recordForCurrentSession?.[RECORD_FIELDS.RESPONSES.FIELDS.STATUS.NAME]
          )
        ) {
          temp.push({
            ...item,
            [RECORD_FIELDS.RESPONSES.FIELDS.CREATED_AT.NAME]:
              recordForCurrentSession?.[RECORD_FIELDS.RESPONSES.FIELDS.CREATED_AT.NAME]
          })
          temp?.sort((a, b) => {
            return (
              moment(b?.[RECORD_FIELDS.RESPONSES.FIELDS.CREATED_AT.NAME]).valueOf() -
              moment(a?.[RECORD_FIELDS.RESPONSES.FIELDS.CREATED_AT.NAME]).valueOf()
            )
          })
        }
      }
    })

    setResult(temp)
  }, [setResult, recordQuestions, questionSetQuestions, filter])

  return result
}

const UserPage = () => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(STATUS.OPEN)
  const questionSet = useSelector(
    (state) =>
      state?.[REDUX_STATE.QUESTION_SET.NAME]?.[REDUX_STATE.QUESTION_SET.FIELDS.QUESTION_SET]
  )
  const isGettingQuestionSet = useSelector(
    (state) =>
      state?.[REDUX_STATE.QUESTION_SET.NAME]?.[
        REDUX_STATE.QUESTION_SET.FIELDS.IS_GETTING_QUESTION_SET
      ]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.ERROR]
  )
  const records = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.RECORDS]
  )
  const record = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.RECORD]
  )
  const isGettingRecord = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.IS_GETTING_RECORD]
  )

  const [selectedRecord, setSelectedRecord] = useState(null)

  const progress = record?.[RECORD_FIELDS.PROGRESS.NAME]
  const currentAvailableOrder = progress?.[RECORD_FIELDS.PROGRESS.FIELDS.ORDER.NAME]
  const completedSessionToday =
    progress?.[RECORD_FIELDS.PROGRESS.FIELDS.COMPLETED_SESSION_TODAY.NAME]
  const maxAllowedSessionPerDay =
    questionSet?.[QUESTION_SET_FIELDS.MAX_ALLOWED_SESSION_PER_DAY.NAME]
  const hasFinishedAllSessionToday = completedSessionToday >= maxAllowedSessionPerDay
  const latestSessionGroup = progress?.[RECORD_FIELDS.PROGRESS.FIELDS.LATEST_SESSION_GROUP.NAME]
  const isRecordCompleted =
    record?.[RECORD_FIELDS.STATUS.NAME] === RECORD_FIELDS.STATUS.ENUMS.COMPLETED

  /**
   * Get question set
   */
  useEffect(() => {
    dispatch(questionSetActions.getQuestionSet())
  }, [dispatch])

  /**
   * Get all records for this user
   */
  useEffect(() => {
    dispatch(recordActions.getRecords())
  }, [dispatch])

  /**
   * Set default record
   */
  useEffect(() => {
    setSelectedRecord(records?.[0]?.[RECORD_FIELDS.ID.NAME])
  }, [records])

  /**
   * Get record data
   */
  useEffect(() => {
    if (selectedRecord) {
      dispatch(recordActions.getRecord({ id: selectedRecord }))
    }
  }, [dispatch, selectedRecord])

  useShowMessages({ error })

  const filteredSections = useFilteredRecord({
    recordQuestions: record?.[RECORD_FIELDS.RESPONSES.NAME],
    questionSetQuestions: questionSet?.[QUESTION_SET_FIELDS.QUESTIONS.NAME],
    filter
  })

  /**
   * Select record
   * @param {String} value - record id
   */
  const handleChangeRecord = (value) => {
    setSelectedRecord(value)
  }

  if (isGettingRecord || isGettingQuestionSet) {
    return (
      <Spin
        fullscreen={true}
        spinning={isGettingRecord || isGettingQuestionSet}
        tip={MESSAGES.LOADING.DEFAULT}
      />
    )
  } else {
    return (
      <>
        <Affix offsetTop={parseInt(getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT).replace('px', ''))}>
          <div
            style={{
              backgroundColor: getCSSVariable(CSS_VARIABLES.BODY_BACKGROUND_COLOR),
              padding: '10px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px'
            }}
          >
            <div style={{ color: 'grey', fontWeight: '600', fontSize: '30px' }}>
              {moment().format(DATE_FORMAT)}
            </div>
            <Select
              disabled={true}
              style={{ width: '200px', height: '30px' }}
              value={selectedRecord}
              onChange={handleChangeRecord}
              options={records?.map((each, index) => {
                return {
                  label: `Trial ${index + 1}`,
                  value: each?.[RECORD_FIELDS.ID.NAME]
                }
              })}
            />
            <Segmented
              options={Object.values(STATUS)}
              block={true}
              style={{ marginBottom: '10px', height: '32px' }}
              value={filter}
              onChange={(value) => setFilter(value)}
            />
          </div>
        </Affix>
        {isRecordCompleted && filter === STATUS.OPEN && (
          <Result icon={null} title='Congratulations, you have done all the questions!' />
        )}
        {!(isRecordCompleted && filter === STATUS.OPEN) && (
          <Timeline
            style={{ overflowY: 'scroll', height: 'calc(100% - 32px - 10px - 40px - 30px - 30px)' }}
            mode={'left'}
            items={filteredSections?.map((item) => {
              const responseForCurrentQuestion = _.find(record?.[RECORD_FIELDS.RESPONSES.NAME], {
                [RECORD_FIELDS.RESPONSES.FIELDS.QUESTION_ID.NAME]:
                  item?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.ID.NAME]
              })
              const isDone =
                responseForCurrentQuestion?.[RECORD_FIELDS.RESPONSES.FIELDS.STATUS.NAME] ===
                RECORD_FIELDS.RESPONSES.FIELDS.STATUS.ENUMS.COMPLETED
              const createdTime =
                responseForCurrentQuestion?.[RECORD_FIELDS.RESPONSES.FIELDS.CREATED_AT.NAME]
              const hasFinishedAllSessionTodayButHasRemainingGroupItem =
                hasFinishedAllSessionToday &&
                latestSessionGroup >= item?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.GROUP.NAME]
              const isAvailable =
                !isDone &&
                item?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.ORDER.NAME] <= currentAvailableOrder &&
                (!hasFinishedAllSessionToday || hasFinishedAllSessionTodayButHasRemainingGroupItem)

              return {
                color:
                  SESSION_STATUS_COLORS?.[
                    responseForCurrentQuestion?.[RECORD_FIELDS.RESPONSES.FIELDS.STATUS.NAME]
                  ] || 'red',
                children: (
                  <div
                    style={{
                      width: '97%',
                      margin: '5px 0',
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px'
                    }}
                  >
                    {isDone && (
                      <Typography.Text strong>
                        {moment(createdTime).format(DATE_FORMAT)}
                      </Typography.Text>
                    )}
                    {isAvailable && !isDone && (
                      <Typography.Text strong>{moment().format(DATE_FORMAT)}</Typography.Text>
                    )}
                    {filter === STATUS.OPEN && (
                      <>
                        {!hasFinishedAllSessionToday && !isAvailable && (
                          <Typography.Text strong style={{ color: 'cornflowerblue' }}>
                            Please finish above first
                          </Typography.Text>
                        )}
                        {hasFinishedAllSessionToday && !isAvailable && (
                          <Typography.Text strong style={{ color: 'red' }}>
                            Please come back tomorrow
                          </Typography.Text>
                        )}
                      </>
                    )}

                    {item?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.TYPE.NAME] ===
                      QUESTION_SET_FIELDS.QUESTIONS.FIELDS.TYPE.ENUMS.SESSION && (
                      <SessionBriefCard
                        question={item}
                        response={responseForCurrentQuestion}
                        recordId={record?.[RECORD_FIELDS.ID.NAME]}
                        isDisable={!isAvailable}
                      />
                    )}
                    {item?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.TYPE.NAME] ===
                      QUESTION_SET_FIELDS.QUESTIONS.FIELDS.TYPE.ENUMS.FEEDBACK && (
                      <FeedbackBriefCard
                        question={item}
                        recordId={record?.[RECORD_FIELDS.ID.NAME]}
                        isDisable={!isAvailable}
                      />
                    )}
                  </div>
                )
              }
            })}
          />
        )}
      </>
    )
  }
}

export default UserPage
