import React from 'react'
import { Button, Popconfirm } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { REDUX_STATE } from '@src/constants'
import { userActions } from '@store/actions/user'
import { USER_FIELDS } from '@constants/db'

const ManageAccount = () => {
  const dispatch = useDispatch()

  const user = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.USER]
  )

  /**
   * Handle delete account
   */
  const handleDeleteAccount = () => {
    dispatch(userActions.deleteUser(user?.[USER_FIELDS.ID.NAME]))
  }

  return (
    <div>
      <Popconfirm
        title='Delete the account'
        description='Are you sure to delete your account? it will delete all data related'
        onConfirm={handleDeleteAccount}
        okText='Confirm'
        cancelText='No'
        placement={'bottom'}
      >
        <Button danger style={{ width: '100%' }}>
          Delete account
        </Button>
      </Popconfirm>
    </div>
  )
}

export default ManageAccount
