import React, { useEffect, useRef, useState } from 'react'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { Button, Form, Input, Spin, Typography } from 'antd'
import { MESSAGES, REDUX_STATE, ROUTE_PATH, ROUTE_STATES } from '@src/constants'
import { USER_FIELDS } from '@constants/db'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '@store/actions/auth'
import PassWordStrengthIndicator from '@components/password-strength-indicator'
import { passwordStrength } from 'check-password-strength'
import { useShowMessages } from '@src/utils/hooks'

const RegisterPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const pathStates = location.state
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const isRegistering = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_REGISTERING]
  )
  const [passStrength, setPassStrength] = useState(null)
  const prevIsRegistering = useRef(isRegistering)

  const SubmitButton = ({ form, children }) => {
    const [submittable, setSubmittable] = useState(false)

    const values = Form.useWatch([], form)

    useEffect(() => {
      form
        .validateFields({
          validateOnly: true
        })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false))
    }, [form, values])
    return (
      <Button type='primary' htmlType='submit' disabled={!submittable}>
        {children}
      </Button>
    )
  }

  useShowMessages({ error })

  /**
   * Redirect to login page after registering
   */
  useEffect(() => {
    if (!isRegistering && !error && prevIsRegistering.current) {
      navigate(ROUTE_PATH.LOGIN.NAME)
    }
    prevIsRegistering.current = isRegistering
  }, [error, isRegistering, navigate])

  /**
   * Submit register data
   * @param {Object} values
   */
  const onFinish = (values) => {
    dispatch(authActions.register({ ...pathStates?.[ROUTE_STATES.PRE_REGISTER_DATA], ...values }))
  }

  /**
   * Handle click cancel
   */
  const handleClickCancel = () => {
    navigate(ROUTE_PATH.ROOT.NAME)
  }

  if (!pathStates?.[ROUTE_STATES.FINISHED_PRE_REGISTER_FORM]) {
    return <Navigate to={ROUTE_PATH.ROOT.NAME} />
  } else {
    return (
      <div
        style={{
          height: '100%'
        }}
      >
        <HoverCardWrapper
          style={{
            width: 'fit-content',
            maxWidth: '350px',
            height: 'fit-content',
            margin: '0 auto'
          }}
        >
          <Spin spinning={isRegistering} tip={MESSAGES.LOADING.REGISTERING}>
            <Form layout='vertical' scrollToFirstError onFinish={onFinish} form={form}>
              <Typography.Title>Register</Typography.Title>
              <Form.Item
                label={MESSAGES.LABEL.EMAIL}
                name={USER_FIELDS.EMAIL.NAME}
                rules={[
                  {
                    required: true,
                    message: MESSAGES.VALIDATION.EMAIL
                  }
                ]}
              >
                <Input type={'email'} />
              </Form.Item>
              <Form.Item
                label={MESSAGES.LABEL.FIRST_NAME}
                name={USER_FIELDS.FIRST_NAME.NAME}
                rules={[
                  {
                    required: true,
                    message: MESSAGES.VALIDATION.FIRST_NAME
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={MESSAGES.LABEL.LAST_NAME}
                name={USER_FIELDS.LAST_NAME.NAME}
                rules={[
                  {
                    required: true,
                    message: MESSAGES.VALIDATION.LAST_NAME
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={MESSAGES.LABEL.PASSWORD_CREATE}
                name={USER_FIELDS.PASSWORD.NAME}
                rules={[
                  {
                    required: true,
                    message: MESSAGES.VALIDATION.PASSWORD
                  },
                  () => ({
                    validator(_, value) {
                      const strength = passwordStrength(value).value
                      setPassStrength(strength)
                      if (['Medium', 'Strong'].includes(strength)) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(MESSAGES.VALIDATION.PASSWORD_TOO_WEAK)
                      }
                    }
                  })
                ]}
              >
                <Input type={'password'} />
              </Form.Item>
              {form.getFieldValue(USER_FIELDS.PASSWORD.NAME) && (
                <PassWordStrengthIndicator
                  strength={passStrength}
                  style={{ margin: '15px auto' }}
                />
              )}
              <Form.Item
                label={MESSAGES.LABEL.PASSWORD_CONFIRM}
                name={'passwordConfirme'}
                dependencies={[USER_FIELDS.PASSWORD.NAME]}
                hasFeedback={true}
                rules={[
                  {
                    required: true,
                    message: MESSAGES.VALIDATION.PASSWORD_CONFIRM
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue(USER_FIELDS.PASSWORD.NAME) === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(MESSAGES.VALIDATION.PASSWORD_CONFIRM_NOT_MATCH)
                      )
                    }
                  })
                ]}
              >
                <Input type={'password'} />
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Form.Item>
                  <Button onClick={handleClickCancel}>Cancel</Button>
                </Form.Item>
                <Form.Item>
                  <SubmitButton form={form}>Submit</SubmitButton>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </HoverCardWrapper>
      </div>
    )
  }
}

export default RegisterPage
