import { ROUTE_PATH } from './routes'
import { REDUX_STATE } from '@constants/redux'
import { ENDPOINTS } from '@constants/endpoints'
import { MESSAGES } from '@constants/messages'
import { RECORD_FIELDS } from '@constants/db'

const CSS_VARIABLES = {
  PRIMARY: '--primary-color',
  SECONDARY: '--secondary-color',
  BODY_BACKGROUND_COLOR: '--body-background-color',
  HEADER_HEIGHT: '--header-height',
  FOOTER_HEIGHT: '--footer-height',
  LOW_Z_INDEX: '--low-z-index',
  MEDIUM_Z_INDEX: '--medium-z-index',
  HIGH_Z_INDEX: '--high-z-index',
  SCROLL_CONTAINER_HEIGHT: '--scroll-container-height',
  FONT_SIZE_HEADER_1: '--font-size-header-1',
  FONT_SIZE_HEADER_2: '--font-size-header-2',
  FONT_SIZE_HEADER_3: '--font-size-header-3',
  FONT_SIZE_HEADER_4: '--font-size-header-4',
  FONT_SIZE_HEADER_5: '--font-size-header-5',
  DEFAULT_FONT_SIZE: '--default-font-size'
}

const DEFAULT_LIMIT = 50

const LIMIT_PER_PAGE = 10

// Used in link, like localhost:3000?token=xxx
const QUERY_PARAMS = {
  TOKEN: 'token'
}

const ROUTE_STATES = {
  REDIRECTED_FROM: 'redirectedFrom',
  FINISHED_PRE_REGISTER_FORM: 'finishedPreRegisterForm',
  PRE_REGISTER_DATA: 'preRegisterData'
}

const ROUTE_QUERY_PARAMS = {
  TAB: {
    NAME: 'tab',
    ENUMS: {
      IMAGE_LABELING: 'image-labelling',
      USERS_MANAGEMENT: 'user-management'
    }
  },
  RECORD: {
    NAME: 'record'
  },
  QUESTION: {
    NAME: 'question'
  }
}

const PASSWORD_STRENGTH = {
  TOO_WEAK: 'Too weak',
  WEAK: 'Weak',
  MEDIUM: 'Medium',
  STRONG: 'Strong'
}

const DATE_FORMAT = 'DD/MM/YYYY'
const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'
const TIME_FORMAT = 'HH:mm'

const SESSION_STATUS_COLORS = {
  open: 'blue',
  [RECORD_FIELDS.RESPONSES.FIELDS.STATUS.ENUMS.COMPLETED]: 'green',
  [RECORD_FIELDS.RESPONSES.FIELDS.STATUS.ENUMS.IN_PROGRESS]: 'orange'
}

export {
  ROUTE_PATH,
  REDUX_STATE,
  ENDPOINTS,
  MESSAGES,
  CSS_VARIABLES,
  DEFAULT_LIMIT,
  LIMIT_PER_PAGE,
  QUERY_PARAMS,
  PASSWORD_STRENGTH,
  ROUTE_STATES,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  ROUTE_QUERY_PARAMS,
  SESSION_STATUS_COLORS,
  TIME_FORMAT
}
