import './index.css'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CSS_VARIABLES, MESSAGES, REDUX_STATE, ROUTE_PATH, ROUTE_STATES } from '@src/constants'
import { Button, Form, Image, Radio, Select, Spin, Typography } from 'antd'
import ScrollDownButton from 'src/components/scroll-down-button'
import ScrollUpButton from '@components/scroll-up-button'
import { authActions } from '@store/actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { getCSSVariable, isExtremeSmallDevices } from '@src/utils'
import APP_ICON from '@src/static/icon.svg'

const QUESTIONS = [
  {
    name: 'PRE_REGISTER_1',
    title:
      'Are you a Canadian Armed Forces Veteran who has transitioned from the military in the past 12 months?',
    inputType: 'RATIO',
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ],
    rules: [
      {
        required: true,
        message: MESSAGES.VALIDATION.EMPTY_SELECTION
      },
      () => ({
        validator(_, value) {
          if (!value || value === 'yes') {
            return Promise.resolve()
          }
          return Promise.reject(MESSAGES.VALIDATION.ANSWER_NEED_TO_BE_YES)
        }
      })
    ]
  },
  {
    name: 'PRE_REGISTER_2',
    title: 'Do you own an android or iphone that is no more than 6 years old?',
    inputType: 'RATIO',
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ],
    rules: [
      {
        required: true,
        message: MESSAGES.VALIDATION.EMPTY_SELECTION
      },
      () => ({
        validator(_, value) {
          if (!value || value === 'yes') {
            return Promise.resolve()
          }
          return Promise.reject(MESSAGES.VALIDATION.ANSWER_NEED_TO_BE_YES)
        }
      })
    ]
  },
  {
    name: 'PRE_REGISTER_3_1',
    title: 'Irritable behavior, angry outbursts, or acting aggressively?',
    description: 'In the past month, how much were you bothered by:',
    inputType: 'RATIO',
    options: [
      {
        label: 'Not at all',
        value: 0
      },
      {
        label: 'A little bit',
        value: 1
      },
      {
        label: 'Moderately',
        value: 2
      },
      {
        label: 'Quite a bit',
        value: 3
      },
      {
        label: 'Extremely',
        value: 4
      }
    ],
    rules: [
      {
        required: true,
        message: MESSAGES.VALIDATION.EMPTY_SELECTION
      }
    ]
  },
  {
    name: 'PRE_REGISTER_3_2',
    title: 'Being "super alert" or watchful or on guard?',
    description: 'In the past month, how much were you bothered by:',
    inputType: 'RATIO',
    options: [
      {
        label: 'Not at all',
        value: 0
      },
      {
        label: 'A little bit',
        value: 1
      },
      {
        label: 'Moderately',
        value: 2
      },
      {
        label: 'Quite a bit',
        value: 3
      },
      {
        label: 'Extremely',
        value: 4
      }
    ],
    rules: [
      {
        required: true,
        message: MESSAGES.VALIDATION.EMPTY_SELECTION
      }
    ]
  },
  {
    name: 'PRE_REGISTER_3_3',
    title: 'Feeling jumpy or easily startled?',
    description: 'In the past month, how much were you bothered by:',
    inputType: 'RATIO',
    options: [
      {
        label: 'Not at all',
        value: 0
      },
      {
        label: 'A little bit',
        value: 1
      },
      {
        label: 'Moderately',
        value: 2
      },
      {
        label: 'Quite a bit',
        value: 3
      },
      {
        label: 'Extremely',
        value: 4
      }
    ],
    rules: [
      {
        required: true,
        message: MESSAGES.VALIDATION.EMPTY_SELECTION
      }
    ]
  },
  {
    name: 'PRE_REGISTER_3_4',
    title: 'Having difficulty concentrating?',
    description: 'In the past month, how much were you bothered by:',
    inputType: 'RATIO',
    options: [
      {
        label: 'Not at all',
        value: 0
      },
      {
        label: 'A little bit',
        value: 1
      },
      {
        label: 'Moderately',
        value: 2
      },
      {
        label: 'Quite a bit',
        value: 3
      },
      {
        label: 'Extremely',
        value: 4
      }
    ],
    rules: [
      {
        required: true,
        message: MESSAGES.VALIDATION.EMPTY_SELECTION
      }
    ]
  },
  {
    name: 'PRE_REGISTER_3_5',
    title: 'Trouble falling or staying asleep?',
    description: 'In the past month, how much were you bothered by:',
    inputType: 'RATIO',
    options: [
      {
        label: 'Not at all',
        value: 0
      },
      {
        label: 'A little bit',
        value: 1
      },
      {
        label: 'Moderately',
        value: 2
      },
      {
        label: 'Quite a bit',
        value: 3
      },
      {
        label: 'Extremely',
        value: 4
      }
    ],
    rules: [
      {
        required: true,
        message: MESSAGES.VALIDATION.EMPTY_SELECTION
      }
    ]
  }
]

const RootPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const isVerifying = useSelector(
    (state) => state?.[REDUX_STATE.TOKEN.NAME]?.[REDUX_STATE.TOKEN.FIELDS.IS_VERIFYING]
  )
  const [step, setStep] = useState(0)
  const [answers, setAnswers] = useState({})
  const [error, setError] = useState(null)

  /**
   * Get current when route change
   */
  useEffect(() => {
    dispatch(authActions.getCurrent())
  }, [dispatch])

  /**
   * Go to next question
   * @param {Object} values
   */
  const onFinish = (values) => {
    const newAnswers = { ...answers, ...values }
    let invalidAnswerNum = 0
    const regex = new RegExp(/PRE_REGISTER_3_\d+$/)
    const testedQuestionNum = Object.keys(newAnswers).reduce((acc, curr) => {
      if (regex.test(curr)) {
        acc += 1
      }
      return acc
    }, 0)

    if (testedQuestionNum > 2) {
      for (const key in newAnswers) {
        const isInvalidAnswer = regex.test(key) && newAnswers[key] === 0
        if (isInvalidAnswer) {
          invalidAnswerNum += 1
        }
      }
    }

    if (invalidAnswerNum > 2) {
      setError(MESSAGES.VALIDATION.INVALID_NUMBER_OF_REQUIRED_ANSWER_FOR_PRE_REGISTER)
      return
    }

    setAnswers(newAnswers)
    if (step < QUESTIONS.length - 1) {
      setStep(step + 1)
    } else {
      navigate(ROUTE_PATH.REGISTER.NAME, {
        state: {
          [ROUTE_STATES.FINISHED_PRE_REGISTER_FORM]: true,
          [ROUTE_STATES.PRE_REGISTER_DATA]: newAnswers
        }
      })
    }
  }

  if (isVerifying) {
    return <Spin spinning={isVerifying} tip={MESSAGES.LOADING.VERIFYING} fullscreen={true} />
  } else {
    return (
      <div className='welcome-page'>
        <div className='page page1'>
          <div
            style={{
              height: `calc(100% - ${getCSSVariable(CSS_VARIABLES.SCROLL_CONTAINER_HEIGHT)})`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px'
            }}
          >
            <Image
              src={APP_ICON}
              alt={'logo'}
              preview={false}
              style={{
                width: getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT),
                height: getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT),
                objectFit: 'cover'
              }}
            />
            <Typography.Title>
              Attention Training for Canadian Armed Forces Veterans
            </Typography.Title>
            <Typography.Title level={3}>Build your mental fitness</Typography.Title>
            <Typography.Title level={3}>Take part now</Typography.Title>
            <Typography.Title
              style={{ zIndex: 1 }}
              level={5}
              onTouchStart={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              <Link to={ROUTE_PATH.LOGIN.NAME} rel={'noopener noreferrer'}>
                Already have an account? Click here to login
              </Link>
            </Typography.Title>
          </div>
          <ScrollDownButton text={'If not, please scroll down'} />
        </div>
        <div className='page page2'>
          <ScrollUpButton />
          <div
            style={{
              width: '100%',
              height: `calc(100% - ${getCSSVariable(CSS_VARIABLES.SCROLL_CONTAINER_HEIGHT)} - ${getCSSVariable(CSS_VARIABLES.SCROLL_CONTAINER_HEIGHT)} - 30px - 10px + 1.5em)`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              marginTop: '10px'
            }}
          >
            <Typography.Title level={3}>
              We’re conducting this study to find out if doing a simple{' '}
              <strong style={{ fontSize: '28px' }}>3-minute</strong> task on your{' '}
              <strong style={{ fontSize: '28px' }}>mobile phone</strong>, known as attention
              training, can help get you into the right frame of mind for the next phase of your
              life. You will be required to answer some questions, and complete{' '}
              <strong style={{ fontSize: '28px' }}>10 three-minute tasks over 3 weeks</strong>.
            </Typography.Title>
          </div>
          <ScrollDownButton text={'Continue to scroll down please'} />
        </div>
        <div className='page page3'>
          <ScrollUpButton />
          <Form
            style={{
              width: '100%',
              height: `calc(100% - ${getCSSVariable(CSS_VARIABLES.SCROLL_CONTAINER_HEIGHT)} - 30px - 10px)`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              marginTop: '10px'
            }}
            onFinish={onFinish}
            form={form}
          >
            <Typography.Title level={isExtremeSmallDevices ? 5 : 4} type={'warning'}>
              Please answer following questions before registering
            </Typography.Title>
            <Typography.Title
              level={isExtremeSmallDevices ? 5 : 4}
              italic={true}
              style={{ color: 'coral' }}
            >
              {QUESTIONS?.[step]?.description}
            </Typography.Title>
            <Typography.Title level={isExtremeSmallDevices ? 5 : 3}>
              {QUESTIONS?.[step]?.title}
            </Typography.Title>
            <Form.Item
              name={QUESTIONS?.[step]?.name}
              rules={QUESTIONS?.[step]?.rules}
              style={{ minWidth: '200px', maxWidth: 'calc(100vw - 10px)' }}
            >
              {QUESTIONS?.[step]?.inputType === 'SELECT' && (
                <Select options={QUESTIONS?.[step]?.options} />
              )}
              {QUESTIONS?.[step]?.inputType === 'RATIO' && (
                <Radio.Group>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      gap: '5px'
                    }}
                  >
                    {QUESTIONS?.[step]?.options?.map((option, index) => (
                      <Radio
                        value={option?.value}
                        key={index}
                        style={{ fontSize: isExtremeSmallDevices ? '15px' : '20px' }}
                      >
                        {option?.label}
                      </Radio>
                    ))}
                  </div>
                </Radio.Group>
              )}
            </Form.Item>
            {error && <Typography.Paragraph type={'danger'}>{error}</Typography.Paragraph>}

            <Form.Item>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '30px', flexDirection: 'row' }}
              >
                {step > 0 && (
                  <Button onClick={() => setStep(step - 1)} style={{ minWidth: '100px' }}>
                    Back
                  </Button>
                )}

                <Button type={'primary'} htmlType={'submit'} style={{ width: '100px' }}>
                  {step < QUESTIONS.length - 1 ? 'Next' : 'Register'}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Typography.Text
            type={'secondary'}
            style={{
              margin: '0 auto',
              height: '20px'
            }}
          >
            {MESSAGES.DESIGNED_BY}
          </Typography.Text>
        </div>
      </div>
    )
  }
}

export default RootPage
