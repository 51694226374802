import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Input, Radio, Select, Typography } from 'antd'

import { getCSSVariable } from '@src/utils'
import { CSS_VARIABLES, REDUX_STATE } from '@src/constants'
import StringToHTMLWrapper from '@components/string-to-html-wrapper'
import { useDispatch, useSelector } from 'react-redux'
import { recordActions } from '@store/actions/record'
import { feedbackFormActions } from '@store/actions/feedback-form'
import PropTypes from 'prop-types'

const QuestionnaireForm = ({ form, recordId, questionId, questionnaireId }) => {
  const feedbackForms = useSelector(
    (state) =>
      state?.[REDUX_STATE.FEEDBACK_FORM.NAME]?.[REDUX_STATE.FEEDBACK_FORM.FIELDS.FEEDBACK_FORM]
  )
  const dispatch = useDispatch()
  const [checkboxStates, setCheckboxStates] = useState({})
  const [textOptionsStates, setTextOptionsStates] = useState({})
  const [questionnaire, setQuestionnaire] = useState(null)

  /**
   * Get all feedback forms
   */
  useEffect(() => {
    dispatch(feedbackFormActions.getFeedbackForms())
  }, [dispatch])

  /**
   * Find the current questionnaire
   */
  useEffect(() => {
    if (feedbackForms) {
      const currentQuestionnaire = feedbackForms.find(
        (feedbackForm) => feedbackForm._id === questionnaireId
      )
      setQuestionnaire(currentQuestionnaire)
    }
  }, [feedbackForms, questionnaireId])

  /**
   * Handle submit
   * @param values
   */
  const onFinish = (values) => {
    // Format the radio-with-text answers
    const formattedValues = { ...values }
    questionnaire.questions.forEach((question) => {
      if (question.type === 'radio') {
        const selectedOption = question.options.find(
          (option) => option.value === values[question.name]
        )
        if (selectedOption?.textOption) {
          formattedValues[question.name] =
            `${values[question.name]} - ${values[`${question.name}_text`]}`
          delete formattedValues[`${question.name}_text`]
        }
      }

      if (question.type === 'checkbox_or_radios' && checkboxStates[question.name]) {
        formattedValues[question.name] = question.checkboxValue
      }
    })

    dispatch(
      recordActions.updateResponse({
        recordId,
        questionId,
        answer: formattedValues
      })
    )
  }

  /**
   * Hide ratio when checkbox checked - for "checkbox-or-radios" type
   * @param questionName
   * @param checked
   */
  const handleCheckboxChange = (questionName, checked) => {
    setCheckboxStates({ ...checkboxStates, [questionName]: checked })
    if (checked) {
      form.resetFields([questionName])
    }
  }

  /**
   * Detect ratio value change
   * @param questionName
   * @param value
   */
  const handleRadioChange = (questionName, value) => {
    setTextOptionsStates({ ...textOptionsStates, [questionName]: value })
  }

  return (
    <div
      style={{
        overflowY: 'scroll',
        height: `calc(100vh - ${getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT)} - ${getCSSVariable(CSS_VARIABLES.FOOTER_HEIGHT)} - 40px)`
      }}
    >
      {questionnaire?.title && (
        <Typography.Title level={4}>{questionnaire?.title}</Typography.Title>
      )}
      {questionnaire?.subTitle && (
        <Typography.Paragraph>
          <StringToHTMLWrapper htmlString={questionnaire?.subTitle} />
        </Typography.Paragraph>
      )}
      {questionnaire?.description && (
        <Typography.Paragraph style={{ backgroundColor: 'lightgrey', padding: '5px' }} strong>
          {questionnaire?.description}
        </Typography.Paragraph>
      )}
      <Form form={form} onFinish={onFinish} layout='vertical' scrollToFirstError>
        {questionnaire?.questions.map((question) => {
          const rules = question?.rules

          switch (question.type) {
            case 'text':
              return (
                <Form.Item
                  key={question.name}
                  label={question.label}
                  name={question.name}
                  rules={rules}
                >
                  <Input placeholder={question.placeholder} />
                </Form.Item>
              )
            case 'textarea':
              return (
                <Form.Item
                  key={question.name}
                  label={question.label}
                  name={question.name}
                  rules={rules}
                >
                  <Input.TextArea placeholder={question.placeholder} />
                </Form.Item>
              )
            case 'ratio':
              return (
                <Form.Item
                  key={question.name}
                  label={question.label}
                  name={question.name}
                  rules={rules}
                  style={{ textAlign: 'left' }}
                >
                  <Radio.Group onChange={(e) => handleRadioChange(question.name, e.target.value)}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                      {question.options.map((option) => (
                        <div
                          key={option.value}
                          style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
                        >
                          <Radio value={option.value}>{option.label}</Radio>
                          {option.textOption &&
                            textOptionsStates[question.name] === option.value && (
                              <Form.Item
                                name={`${question.name}_text`}
                                noStyle
                                rules={[{ required: true, message: 'This field is required' }]}
                              >
                                <Input
                                  style={{ width: '100%' }}
                                  placeholder={option.textOption.placeholder}
                                />
                              </Form.Item>
                            )}
                        </div>
                      ))}
                    </div>
                  </Radio.Group>
                </Form.Item>
              )
            case 'select':
              return (
                <Form.Item
                  key={question.name}
                  label={question.label}
                  name={question.name}
                  rules={rules}
                >
                  <Select placeholder='Select an option'>
                    {question.options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            case 'checkbox_or_radios':
              return (
                <Form.Item
                  key={question.name}
                  label={
                    <span>
                      {question.label}{' '}
                      <Checkbox
                        onChange={(e) => handleCheckboxChange(question.name, e.target.checked)}
                      />
                    </span>
                  }
                  valuePropName='checked'
                >
                  {!checkboxStates[question.name] && (
                    <Form.Item
                      key={question.name}
                      name={question.name}
                      rules={rules}
                      style={{ textAlign: 'left' }}
                    >
                      <Radio.Group>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                            justifyContent: 'left'
                          }}
                        >
                          {question.options.map((option) => (
                            <Radio key={option.value} value={option.value}>
                              {option.label}
                            </Radio>
                          ))}
                        </div>
                      </Radio.Group>
                    </Form.Item>
                  )}
                </Form.Item>
              )
            default:
              return null
          }
        })}
      </Form>
    </div>
  )
}

QuestionnaireForm.propTypes = {
  form: PropTypes.shape({
    resetFields: PropTypes.func
  }),
  recordId: PropTypes.string,
  questionId: PropTypes.string,
  questionnaireId: PropTypes.string
}

export default QuestionnaireForm
