import React, { useEffect, useRef } from 'react'
import { getCSSVariable, isMobile } from '@src/utils'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { Affix, Typography } from 'antd'
import BasicActionButtons from '@components/basic-action-buttons'
import { useDispatch, useSelector } from 'react-redux'
import { CSS_VARIABLES, REDUX_STATE, ROUTE_PATH } from '@src/constants'
import { USER_FIELDS } from '@constants/db'
import { authActions } from '@store/actions/auth'
import { useNavigate } from 'react-router-dom'
import SecurePDFViewer from '@components/secure-pdf-viewer'
import PlainStatementPDF from '@src/static/PLS-ACT.pdf'

const PlainLanguageStatementPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.USER]
  )
  const isUpdatingProfile = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_UPDATING]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const prevIsUpdating = useRef(isUpdatingProfile)
  const userId = user?.[USER_FIELDS.ID.NAME]

  /**
   * Go to session page after confirm
   */
  useEffect(() => {
    if (!error && !isUpdatingProfile && prevIsUpdating.current) {
      navigate(ROUTE_PATH.CONSENT_FORM.NAME)
    }
    prevIsUpdating.current = isUpdatingProfile
  }, [navigate, error, isUpdatingProfile])

  const onConfirm = () => {
    dispatch(
      authActions.updateProfile({
        id: userId,
        body: { [USER_FIELDS.READ_PLAIN_LANGUAGE_STATEMENT.NAME]: true }
      })
    )
  }

  return (
    <div>
      <HoverCardWrapper
        style={{
          margin: '0 auto',
          width: isMobile ? '100%' : '500px'
        }}
      >
        <Affix offsetTop={parseInt(getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT).replace('px', ''))}>
          <div
            style={{
              marginBottom: '20px',
              height: '90px',
              backgroundColor: 'white'
            }}
          >
            <BasicActionButtons
              okText={'Confirm'}
              cancelText={'Delete account'}
              cancelIsDanger={true}
              style={{
                marginBottom: '10px'
              }}
              onOk={onConfirm}
              onCancel={() => dispatch(authActions.deleteAccount(userId))}
            />
            <Typography.Title level={4}>Plain Language Statement</Typography.Title>
          </div>
        </Affix>
        <div style={{ overflowY: 'scroll', height: `calc(100% -100px)` }}>
          <Typography.Paragraph>
            Please read through the Plain Language Statement below. It will provide you with further
            information about the project so you can decide if you would like to take part in this
            research.
          </Typography.Paragraph>
          <SecurePDFViewer file={PlainStatementPDF} />
        </div>
      </HoverCardWrapper>
    </div>
  )
}

export default PlainLanguageStatementPage
