import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DATE_TIME_FORMAT, ROUTE_PATH } from '@src/constants'
import moment from 'moment/moment'
import { Button, Image } from 'antd'
import HowToLabelImageStep1 from '@src/static/how-to-label-image-1.png'
import HowToLabelImageStep2 from '@src/static/how-to-label-image-2.png'
import HowToLabelImageStep3 from '@src/static/how-to-label-image-3.png'
import PropTypes from 'prop-types'

const HELPER_OPTIONS = {
  SESSIONS: 'How to start session',
  SEND_EMAIL: 'Contact support',
  ROOT: 'About website',
  HOW_TO_UPLOAD_IMAGES: 'How to upload images',
  LABEL_IMAGE: 'How to label images',
  USERS_MANAGEMENT: 'How to manage users'
}

const NEW_LINE_CHAR = '%0D%0A'
const DEFAULT_EMAIL_SUBJECT = 'Inquiry about attention control platform'
const DEFAULT_EMAIL_BODY = `Hello, ${NEW_LINE_CHAR}${NEW_LINE_CHAR} I'd like to inquire about: ${NEW_LINE_CHAR}${NEW_LINE_CHAR}`

const HelperOptions = ({ sendMessage }) => {
  const location = useLocation()
  const path = location?.pathname
  const [results, setResults] = useState([])

  /**
   * Init options
   */
  useEffect(() => {
    if (path.includes(ROUTE_PATH.SESSIONS.NAME)) {
      setResults([HELPER_OPTIONS.SEND_EMAIL, HELPER_OPTIONS.SESSIONS])
    } else if (path === ROUTE_PATH.ROOT.NAME) {
      setResults([HELPER_OPTIONS.SEND_EMAIL, HELPER_OPTIONS.ROOT])
    } else if (path.includes(ROUTE_PATH.ADMIN.NAME)) {
      setResults([
        HELPER_OPTIONS.SEND_EMAIL,
        HELPER_OPTIONS.HOW_TO_UPLOAD_IMAGES,
        HELPER_OPTIONS.LABEL_IMAGE,
        HELPER_OPTIONS.USERS_MANAGEMENT
      ])
    }
  }, [path])

  const handleSend = (type) => {
    const result = [
      {
        content: <strong>{type}</strong>,
        isRobot: false,
        time: moment().format(DATE_TIME_FORMAT)
      }
    ]
    if (type === HELPER_OPTIONS.SEND_EMAIL) {
      result.push({
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <a
              href={`mailto: admin@example.com?subject=${DEFAULT_EMAIL_SUBJECT}&body=${DEFAULT_EMAIL_BODY}`}
              rel='noopener noreferrer'
            >
              Click me to send email to: [admin@example.com]
            </a>
          </div>
        ),
        isRobot: true,
        time: moment().format(DATE_TIME_FORMAT)
      })
    } else if (type === HELPER_OPTIONS.SESSIONS) {
      result.push({
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div>
              There are <strong>10</strong> sessions, and you can start 1 or 2 at most every day,
            </div>
            <div>
              Usually each session will follow up an <strong>&quot;Abbreviated PCL-5&quot;</strong>{' '}
              questionnaire, don&apos;t forget to complete the questionnaire as well
            </div>
            <div>
              Please finish as many trials as possible to earn more exp, and each session will
              expired after <strong>1 calendar day</strong>, please try your best complete each
              session
            </div>
          </div>
        ),
        isRobot: true,
        time: moment().format(DATE_TIME_FORMAT)
      })
    } else if (type === HELPER_OPTIONS.ROOT) {
      result.push({
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div>
              We’re conducting this study to find out if doing a simple 3-minute task on your mobile
              phone, known as attention training, can help get you into the right frame of mind for
              the next phase of your life. You will be required to answer some questions, and
              complete 10 three-minute tasks over 3 weeks.
            </div>
          </div>
        ),
        isRobot: true,
        time: moment().format(DATE_TIME_FORMAT)
      })
    } else if (type === HELPER_OPTIONS.HOW_TO_UPLOAD_IMAGES) {
      result.push({
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div>
              <strong>Step 1</strong>: prepare image files and name them in &quot;AF03ANS&quot;
              pattern, start from 2nd character, <strong>F</strong> for female, <strong>M</strong>{' '}
              for male, then followed by identity number (at least 2 digits), same person has same
              number, then <strong>ANS</strong> for angry face, <strong>NES</strong> for neutral
              face
            </div>
            <div>
              <strong>Step 2</strong>: drag or click the file uploading area to upload
            </div>
          </div>
        ),
        isRobot: true,
        time: moment().format(DATE_TIME_FORMAT)
      })
    } else if (type === HELPER_OPTIONS.LABEL_IMAGE) {
      result.push({
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div>
              <strong>Step 1</strong>: Click the image want to label at left side, and image will
              show on the right{' '}
              <Image src={HowToLabelImageStep1} width={300} style={{ objectFit: 'cover' }} />
            </div>
            <div>
              <strong>Step 2</strong>: Please follow the instruction to draw dots at specific
              position{' '}
              <Image src={HowToLabelImageStep2} width={300} style={{ objectFit: 'cover' }} />
            </div>
            <div>
              Each picture needs 4 dots in total, and you need to draw all 4 dots before saving
            </div>
            <div>
              Dot will <strong>randomly select one</strong> of positions to display
            </div>
            <div>
              <strong>Step 3</strong>: Click save to save your labelling, or{' '}
              <strong>right click</strong> label box to delete it or use redo button
            </div>
            <div>
              <Image src={HowToLabelImageStep3} width={300} style={{ objectFit: 'cover' }} />
            </div>
            <div>
              Please use <strong>computer</strong> for more functions, mobile only support basic
              functions
            </div>
          </div>
        ),
        isRobot: true,
        time: moment().format(DATE_TIME_FORMAT)
      })
    } else if (type === HELPER_OPTIONS.USERS_MANAGEMENT) {
      result.push({
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div>
              As admin, you can <strong>delete</strong> users, and <strong>edit</strong> basic
              information of users
            </div>
            <div>
              On <strong>computer</strong>, you can use full functions
            </div>
            <div>
              On <strong>mobile</strong>, you can only view
            </div>
          </div>
        ),
        isRobot: true,
        time: moment().format(DATE_TIME_FORMAT)
      })
    }

    sendMessage(result)
  }

  return (
    <div style={{ padding: '10px', flexWrap: 'wrap', gap: '5px' }}>
      {results.map((each, index) => {
        return (
          <Button
            key={index}
            style={{ borderRadius: '50px', margin: '5px' }}
            onClick={() => handleSend(each)}
          >
            {each}
          </Button>
        )
      })}
    </div>
  )
}

HelperOptions.propTypes = {
  sendMessage: PropTypes.func.isRequired
}

export default HelperOptions
